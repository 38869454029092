@tailwind base;

/* Write your own custom base styles here */

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Write you own custom component styles here */

/* Start purging... */
@tailwind utilities;

.h1 {
  font-weight: bold;
}

@font-face {
  font-family: 'Mazzard';
  src: url("../public/fonts/MazzardH-Bold.otf");
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'Mazzard';
  src: url("../public/fonts/MazzardH-Regular.otf");
  font-style: normal;
  font-weight: normal;
}

/* Stop purging. */

/* Your own custom utilities */

.has-text-align-left {
  @apply text-left;
}

.has-text-align-center {
  @apply text-center;
}

.has-text-align-right {
  @apply text-right;
}

.has-large-font-size {
  @apply text-4xl;
}

.btn-ghost.btn-active {
  @apply text-white;
}

.btn-ghost:hover {
  @apply text-white;
}

.btn {
  @apply rounded-none;
}

.btn-round {
  @apply rounded-full;
  text-align: center;
  //width: 45px;
  //height: 45px;
  //border: none;
  //border-radius: 50%;
  ////box-shadow: 0 2px 10px #36363626;
}

.pointer-events-initial {
  pointer-events: initial;
}

.scroller {
  scroll-snap-type: x mandatory;
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    //background-color: #F5F5F5;
    background-color: transparent;
  }

  &::-webkit-scrollbar
  {
    width: 1px;
    height: 3px;
  }

  &::-webkit-scrollbar-thumb
  {
    @apply bg-gray-500;
    border-radius: 9px;
    background-clip: content-box;
  }

  & .scroller-item {
    scroll-snap-align: start;
  }

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scroll-behavior: smooth;
}

.outside-click-parent {
  > div {
    width: 100%;
  }
}

.flip {
  transform: scale(-1);
}

.round-button {
  width: 45px;
  height: 45px;
  border: none;
  border-radius: 50%;
  box-shadow: 0 2px 10px #36363626;
}

.select-filter {
  @apply select;
  @apply font-normal;
  @apply rounded-none;
  @apply border-none;
  @apply border-b;
  @apply border-gray-300;
  @apply underline;

  //border-bottom: 1px solid #ababab;
}

.hero-flex {
  display: flex;
  background-size: cover;
  background-position: center
}

.scroller {
}

.hero-content-flex {
}

.small-gap {
 > * + * {
    padding-left: 0.5rem !important;
  }
}

.collapsable-menu {
  @apply menu;


  .collapse-item {
    display: block;
    //padding-bottom: 0;

    &:hover {
      background-color: unset;
    }
  }
}

.hero-overlay-flex {
  width: 100%;
  height: 100%;
  position: absolute;
  @apply hero-overlay;
}

.alignfull {
  @apply w-screen relative;

  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  right: 50%;
}


.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;

}

#dots #dot1{
  animation: load 1s infinite;
}

#dots #dot2{
  animation: load 1s infinite;
  animation-delay: 0.2s;
}

#dots #dot3{
  animation: load 1s infinite;
  animation-delay: 0.4s;
}

.featured-brand-image {
  border: solid 2px #4c4c4c !important;
}

@keyframes load{
  0%{
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}

@media (hover: none) {
  .btn-success:hover {
    @apply btn-success;
  }
  .btn-info:hover {
    @apply btn-info;
    background: none !important;
  }
  .btn-primary:hover {
    @apply btn-primary;
  }
  .btn-ghost:hover {
    @apply btn-ghost;
    background: none !important;
  }
  .btn-accent:hover {
    @apply btn-accent;
    background: none !important;
  }
  .btn-outline:hover {
    @apply btn-outline;
    background: none !important;
    color: unset !important;
  }

  //button:focus {
  //  background:red;
  //}
}


@responsive {
  .shadow-top-md {
    -webkit-box-shadow: 0px -8px 15px -1px rgba(0,0,0,0.10);
    box-shadow: 0px -8px 15px -1px rgba(0,0,0,0.10);
  }

  .shadow-bottom-md {
    -webkit-box-shadow: 0px -8px 15px -1px rgba(0,0,0,0.10);
    box-shadow: 0px -8px 15px -1px rgba(0,0,0,0.10);
  }

  .inner-shadow-right-md {
    -moz-box-shadow:    inset 0 0 10px #000000;
    -webkit-box-shadow: inset 0 0 10px #000000;
    box-shadow:         inset 0 0 10px #000000;
    //-webkit-box-shadow: 0px -8px 15px -1px rgba(0,0,0,0.10);
    //box-shadow: 0px -8px 15px -1px rgba(0,0,0,0.10);
  }

  .text-shadow {
    text-shadow: 0 2px 4px rgba(0,0,0,0.10);
  }

  .text-shadow-heavy {
    text-shadow: 0 2px 4px rgba(0,0,0,0.60);
  }

  .text-shadow-md {
    text-shadow: 0 4px 8px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.08);
  }

  .text-shadow-lg {
    text-shadow: 0 15px 30px rgba(0,0,0,0.11), 0 5px 15px rgba(0,0,0,0.08);
  }

  .text-shadow-none {
    text-shadow: none;
  }

  .no-scrollbar {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.inline-html {
  ul {
    list-style-type: disc;
    list-style-position: inside;

    //::marker {
    //  margin-right: -1em !important;
    //}
  }

  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
}